<template>
  <v-container class="general">
    <!-- Create apps -->
    <template v-if="!isLoading && canCreateGroup()">
      <v-layout v-if="!groups.length > 0" row wrap class="mt-50 text-center">
        <v-flex class="xs12 mb-2">
          <v-btn
            fab
            class="theme-accent-bg white--text fab-xl"
            @click="createGroup"
          >
            <v-icon dark>add</v-icon>
          </v-btn>
        </v-flex>
        <v-flex class="xs12 caption grey--text">Create course</v-flex>
      </v-layout>
    </template>
    <!-- List apps -->
    <template v-if="groups && groups.length > 0">
      <v-card class="mb-4 pa-4 border-radius theme-secondary-bg">
        <div class="display-2 font-weight-black theme-on-secondary">
          {{ $t("course_list.title") }}
        </div>
      </v-card>
      <v-layout v-if="groups && groups.length > 0" row wrap class="mx-n1">
        <v-flex v-for="(group, i) in groups" :key="`course-${i}`" xs3 px-1 pb-2>
          <v-hover v-slot:default="{ hover }">
            <v-card
              hover
              class="border-radius fill-height"
              :class="[group.published ? '' : 'opacity']"
              @click="showGroup(group.id)"
            >
              <div class="opacity-0 text-right" :class="{ 'on-hover': hover }">
                <div v-if="!canCreateGroup()" style="padding-top: 44px"></div>
                <div v-if="canCreateGroup()">
                  <div v-if="group.is_template" style="padding-top: 44px"></div>
                  <div v-if="!group.is_template" class="pa-1">
                    <v-tooltip top>
                      <v-btn
                        slot="activator"
                        icon
                        flat
                        class="ma-0"
                        @click.stop="duplicateGroup(group.id)"
                      >
                        <font-awesome-icon
                          icon="clone"
                          class="theme-accent fai-small"
                        ></font-awesome-icon>
                      </v-btn>
                      <span>{{ $t("course_list.duplicate_tooltip") }}</span>
                    </v-tooltip>
                    <template v-if="canDeleteGroup()">
                      <v-tooltip top>
                        <v-btn
                          slot="activator"
                          icon
                          flat
                          class="ma-0"
                          @click.stop="deleteGroup(group.id)"
                        >
                          <font-awesome-icon
                            icon="trash"
                            class="theme-accent fai-small"
                          ></font-awesome-icon>
                        </v-btn>
                        <span>{{ $t("course_list.delete_tooltip") }}</span>
                      </v-tooltip>
                    </template>
                    <v-tooltip top>
                      <v-btn
                        slot="activator"
                        icon
                        flat
                        class="ma-0"
                        @click.stop="editGroup(group.id)"
                      >
                        <font-awesome-icon
                          icon="pen"
                          class="theme-accent fai-small"
                        ></font-awesome-icon>
                      </v-btn>
                      <span>{{ $t("course_list.edit_tooltip") }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
              <v-card-text class="pt-0 text-center">
                <div class="mb-3">
                  <v-avatar :size="80" :class="[groupColorClass(group.id)]">
                    <div
                      class="display-1 white--text text-uppercase font-weight-bold"
                    >
                      {{ group.name.substring(0, 1) }}
                    </div>
                  </v-avatar>
                </div>
                <div class="text-uppercase font-weight-bold">
                  {{ group.name }}
                </div>
                <div v-if="group.attributes" class="caption grey--text">
                  {{ formatDate(group.attributes.course_start_date) }}
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-flex>
        <v-flex v-if="totalPages > 1" class="xs12 mt-4 text-xs-center">
          <v-pagination
            v-model="page"
            :length="totalPages"
            :total-visible="totalVisible"
            :disabled="isLoading"
            @input="listGroups"
          ></v-pagination>
        </v-flex>
      </v-layout>
    </template>
    <AppSpeedDial
      v-if="groups && groups.length > 0 && canCreateGroup()"
      @click="createGroup"
      :hint="'Create new course'"
    />
    <CourseDuplicateDialog ref="courseDuplicateDialog" />
    <CourseDeleteDialog ref="courseDeleteDialog" />
  </v-container>
</template>

<script>
import { permissionMixin } from "@/mixins/permissionMixin.js";
import CourseDuplicateDialog from "@/components/CourseListPage/CourseDuplicateDialog.vue";
import CourseDeleteDialog from "@/components/CourseListPage/CourseDeleteDialog.vue";
import moment from "moment-timezone";

export default {
  mixins: [permissionMixin],
  data: () => ({
    isLoading: false,
    groups: [],
    page: 1,
    perPage: 16,
    totalPages: null,
    totalVisible: 5,
  }),
  computed: {
    groupMenu() {
      return this.$store.getters.getLeftMenu;
    },
  },
  components: {
    CourseDuplicateDialog,
    CourseDeleteDialog,
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page, 10);
    }
    this.listGroups();
  },
  methods: {
    formatDate(date) {
      return date
        ? moment.tz(date, this.userTimezone).format("D. MMMM YYYY")
        : "";
    },
    listGroups() {
      this.$router.replace(
        {
          query: {
            page: this.page,
          },
        },
        () => {},
      );

      const params = {
        sortby: "updated_at",
        sortdir: "desc",
        managed: 1,
        with_attributes: 1,
        page: this.page,
        per_page: this.perPage,
      };

      this.isLoading = true;

      this.$api.groups.list(params).then(
        (response) => {
          this.isLoading = false;

          this.groups = response.data.data;
          this.totalPages = response.data.pagination.total_pages;
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    createGroup() {
      this.$router.push(
        {
          name: "courses_create",
        },
        () => {},
      );
    },
    showGroup(groupId) {
      if (!groupId) return;

      return this.$router.push(
        {
          name: "course",
          params: {
            group_id: groupId,
          },
        },
        () => {},
      );
    },
    editGroup(id) {
      if (!id) return;

      this.$api.groups.update(id, { published: 0 }).then(
        (response) => {
          if (!response) return;

          this.$router.push(
            {
              name: "courses_edit",
              params: {
                group_id: id,
              },
            },
            () => {},
          );
        },
        (error) => this.errorMessageShow(error),
      );
    },
    duplicateGroup(id) {
      this.$refs.courseDuplicateDialog.open(id);
    },
    async deleteGroup(id) {
      const group = this.groups.find((el) => el.id === id);

      const isConfirmed = await this.$refs.courseDeleteDialog.open(group.name);

      if (!isConfirmed) return;

      this.isLoading = true;

      this.$api.groups.delete(id).then(
        () => {
          this.isLoading = false;

          this.listGroups();
        },
        () => {
          this.isLoading = false;

          this.$store.dispatch("addErrorNotification", {
            message: this.$t("course_list.delete_error"),
          });
        },
      );
    },
    groupColorClass(id) {
      if (!id) return;
      return " color-" + (id % 10);
    },
  },
};
</script>

<style lang="scss" scoped>
.fab-xl {
  min-width: 100px;
  min-height: 100px;
  .v-icon {
    font-size: 40px;
    font-weight: 700;
  }
}

.mt-50 {
  margin-top: 200px;
}

.mx-n1 {
  margin-left: -4px;
  margin-right: -4px;
}

.opacity {
  opacity: 0.5;
}

.opacity-0 {
  transition: 0.4s;
  opacity: 0;

  &.on-hover {
    opacity: 1;
  }
}

.fai-small {
  font-size: 12px;
}

.color {
  &-0 {
    background: #ffc107;
  }
  &-1 {
    background: #f44336;
  }
  &-2 {
    background: #e91e63;
  }
  &-3 {
    background: #9c27b0;
  }
  &-4 {
    background: #673ab7;
  }
  &-5 {
    background: #3f51b5;
  }
  &-6 {
    background: #00bcd4;
  }
  &-7 {
    background: #009688;
  }
  &-8 {
    background: #4caf50;
  }
  &-9 {
    background: #ff9800;
  }
}
</style>
