import { mapGetters } from "vuex";

export const permissionMixin = {
  computed: {
    ...mapGetters(["currentUser", "currentUserPermissions", "userRolesInGroup", "userGroupPolicies"]),
  },
  methods: {
    canCreateGroup() {
      if (!this.currentUser || !this.currentUser.roles) {
        return;
      }

      const isAdmin = this.currentUser.roles.includes("admin");

      if (isAdmin) return true;

      if (process.env.VUE_APP_CAN_CREATE_GROUPS === "true") return true;

      const hasGroupStorePermission = this.currentUserPermissions.includes("group_store");

      if (hasGroupStorePermission) return true;

      return;
    },
    canEditGroup() {
      if (!this.currentUser || !this.currentUser.roles) {
        return;
      }

      const isAdmin = this.currentUser.roles.includes("admin");

      if (isAdmin) return true;

      if (process.env.VUE_APP_CAN_CREATE_GROUPS === "true") return true;

      const hasGroupStorePermission = this.currentUserPermissions.includes("group_update");

      if (hasGroupStorePermission) return true;

      return;
    },
    canDeleteGroup() {
      if (!this.currentUser || !this.currentUser.roles) {
        return;
      }

      const isAdmin = this.currentUser.roles.includes("admin");

      if (isAdmin) return true;

      const hasGroupStorePermission = this.currentUserPermissions.includes("group_destroy");

      if (hasGroupStorePermission) return true;

      return;
    },
    canDo(groupPolicies){

      if(!groupPolicies || !groupPolicies.length)return false;
      if(!this.userRolesInGroup || !this.userRolesInGroup.length)return false;
      if(!this.userGroupPolicies || !this.userGroupPolicies.length) return false;

      if (groupPolicies && groupPolicies.length) {
        const currentUserPolicies = this.userGroupPolicies.filter((policy) =>
          policy.group_roles.some((role) =>
            this.userRolesInGroup.includes(role.name),
          ),
        );
       
        return groupPolicies.every((groupPolicy) =>
          currentUserPolicies
            .map((userPolicy) => userPolicy.name)
            .includes(groupPolicy),
        );
      }
      return true;
    },
  },

};
